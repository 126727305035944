@font-face {
  font-family: "Suisse";
  src: url("../Fonts/Suisse.ttf") format("truetype");
}

@font-face {
  font-family: "Xanh";
  src: url("../Fonts/X.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

pre {
  font-family: "Xanh", monospace;
}

.heading--weight-normal {
  font-weight: normal;
}

.app-container-center {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  /* "Hello" bar */
  margin-top: -34px;
  /* App bar */
  margin-bottom: 75px;
}

@media screen and (max-width: 720px) {
  .app-container-center {
    margin-top: unset;
    margin-bottom: unset;
  }
}

.app-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  max-width: 960px;
  margin: 0 auto;
  text-transform: lowercase !important;
  box-sizing: content-box;
}

@media screen and (max-width: 720px) {
  .app-container {
    padding-bottom: 100px;
  }
}

@media (max-width: 980px) {
  .app-container {
    justify-content: center;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-background {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
}

@media screen and (max-width: 720px) {
  .app-background {
    position: fixed;
  }
}

.app-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-image {
  width: 100%;
  height: 205px;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid rgba(49, 49, 49, 0.5);
  margin-bottom: 10px;
  /*  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
  transition: 0.3s ease-out;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

.app-name {
  line-height: 100%;
}

.app-name {
  font-family: "Suisse", sans-serif;
  font-size: 16px;
  color: #f1f1f1;
  text-align: center;
  width: 100%;
  margin: 2px 0;
}

.app-description {
  font-family: "Suisse", sans-serif;
  font-size: 16px;
  color: #f1f1f1;
  text-align: center;
  width: 100%;
  margin: 2px 0;
}

.app-description {
  font-size: 12px;
  opacity: 0.55;
}

.app-meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .app-meta {
    width: 100%;
    justify-content: space-between;
  }
}

.app-meta--text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 720px) {
  .app-meta--text {
    justify-content: flex-start;
  }

  .app-meta--text > h2 {
    text-align: left;
  }

  .app-meta--text > p {
    text-align: left;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 205px;
  margin-bottom: 10px;
}

/* ... (rest of the CSS) */

.launch-button {
  opacity: 0;
  border-radius: 9999px;
  border: 1px solid #131313;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.25) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow:
    0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 7px 28px;
  font-family: "Suisse", sans-serif;
  font-size: 14px;
  color: #f1f1f1;
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.launch-button--desktop {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.launch-button--mobile {
  display: none;
}

@media screen and (max-width: 720px) {
  .launch-button--desktop {
    display: none;
  }

  .launch-button--mobile {
    display: block;
  }
}

@media (max-width: 960px) {
  .launch-button {
    opacity: 1;
    visibility: visible;
  }
}

.image-container:hover .launch-button {
  opacity: 1;
  visibility: visible;
}

.iframe-container {
  position: fixed;
  top: 30px;
  left: 20px;
  right: 20px;
  bottom: 90px;
  z-index: 0;
  border-radius: 8px;
  background: #131313;
  overflow: hidden;
}

.iframe-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 2px solid #272727;
  background: #222;
}

.close-button,
.share-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #f1f1f1;
  font-size: 12px;
}

.app-iframe {
  width: 100%;
  height: calc(100% - 30px); /* Subtracting the toolbar height */
  border: none;
}

/* ... (rest of the CSS) */

.dock {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: 54px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  border-radius: 10px;
  box-shadow:
    0px 1px 1px 0px rgba(255, 255, 255, 0.15) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.dock-item {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  transition: 0.2s;
}

.dock-item:hover {
  transform: translateY(-2px);
}

.dock-image {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.active-indicator {
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 66%;
  height: 2px;
  background: white;
  border-radius: 1000px;
}

.dock-button {
  margin-left: 20px;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #000;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.29) 0%,
      rgba(255, 255, 255, 0.65) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    linear-gradient(0deg, #222 0%, #222 100%), #d9d9d9;
  background-blend-mode: overlay, normal, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset;
  cursor: pointer;
  color: #f1f1f1;
  font-family: "Suisse", sans-serif;
}

.dock-button:last-child {
  margin-left: 0px;
}

.dock {
  /* ... (existing styles) */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Add some padding for better appearance */
}

.spacer {
  flex: 1;
}

.dock-icons {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 960px) {
  .dock-icons {
    display: none;
  }
}

.dock-buttons {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

.connected-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #f8ffff;
  border-radius: 50%; /* Makes it a circle */
  filter: drop-shadow(0px 0px 4px #00ff38) drop-shadow(0px 0px 1px #05ce31);
  margin-right: 5px; /* Space between the icon and the address */
  vertical-align: middle; /* Aligns the icon with the text */
}

.settings:after {
  content: "";
  position: absolute;
  z-index: 10000000000000;
  width: 24px;
  background-repeat: no-repeat;
  left: 0;

  background: rgba(255, 255, 255, 0.1);
  filter: blur(10px);
  height: 100%;
  top: 0;
}

.settings:before {
  content: "";
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  filter: blur(10px);
  z-index: 10000000000000;
  width: 24px;
  background-repeat: no-repeat;
  right: 0;
  transform: rotateY(180deg);
}

.modalNew {
  height: 100%;
}

.modalNew:after {
  content: "";
  background-image: url("../Images/highlight-top.svg");
  position: absolute;
  z-index: 10000000000000;
  width: 100%;
  height: 24px;
  left: 24px;
  right: 0px;
  top: 0px;
  background-repeat: no-repeat;
}

.settings h2 {
  font-family: "Suisse", sans-serif;
  font-size: 18px;
  color: #f1f1f1;
  padding-bottom: 10px;
}

.settings h3 {
  font-family: "Suisse", sans-serif;
  font-size: 16px;
  color: #f1f1f1;
  font-weight: normal;
  padding-bottom: 0px;
}

.settings input[type="radio"] {
  margin: 3px 3px 0px 5px;
  transform: translateY(-1px);
}

.open-option {
  font-size: 14px;
  color: #f1f1f1;
  font-weight: normal;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.open-option label {
  margin-bottom: 10px;
}

.username-input {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: linear-gradient(91deg, #0e0e0e -0.48%, #070707 100%);
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset;
  padding: 8px 16px;
  color: #f1f1f1;
  font-family: "Suisse", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #131313;
  width: -webkit-fill-available;
  width: fill-available;
}

.wallpaper-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 10px;
  background: #000;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset;
  padding: 10px;
}

.wallpaper {
  width: calc(33.33% - 10px); /* Subtracting the gap */
  height: 50px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;
  box-sizing: border-box;
}

.wallpaper__active {
  border: 2px solid rgba(187, 219, 255, 0.75);
  box-shadow: 0px 4px 4px 0px rgba(187, 219, 255, 0.25);
  opacity: 1;
}

.confirm-button {
  border-radius: 9999px;
  height: 32px;
  padding: 7px 28px;
  padding-top: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #f1f1f1;
  font-family: "Suisse", sans-serif;
  cursor: pointer;
  font-size: 14px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #131313;
  background: linear-gradient(
      178deg,
      rgba(255, 255, 255, 0.11) 1.34%,
      rgba(255, 255, 255, 0.25) 5.7%,
      rgba(255, 255, 255, 0) 45.31%,
      rgba(255, 255, 255, 0) 77.57%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow:
    0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.mask-container {
  position: fixed;
  width: 100%;
  height: 100lvh;
  inset: 0px;
  z-index: 2147483647;
  pointer-events: none;
  background: none;
  /*    mix-blend-mode: multiply;*/
}

.img1 {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background: none;
  opacity: 1;
  z-index: 2147483648;
}

.img2 {
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  background: none;
  opacity: 0.1;
}

.crt-scanlines {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/*Stolen from http://aleclownes.com/2017/02/01/crt-display.html*/

/*This adds a "crt scanlines" effect to the screen*/
.crt-scanlines::before {
  content: " ";
  display: block;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 80%, rgba(0, 0, 0, 0.1) 80%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.01),
      rgba(0, 0, 255, 0.02)
    );
  z-index: 2;
  background-size:
    100% 2px,
    3px 100%;
  pointer-events: none;
}

@media screen and (max-width: 720px) {
  .crt-scanlines::before {
    content: none;
  }
}

/*This adds a "flickering screen" effect*/
.crt-flicker::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }
  5% {
    opacity: 0.34769;
  }
  10% {
    opacity: 0.23604;
  }
  15% {
    opacity: 0.90626;
  }
  20% {
    opacity: 0.18128;
  }
  25% {
    opacity: 0.83891;
  }
  30% {
    opacity: 0.65583;
  }
  35% {
    opacity: 0.67807;
  }
  40% {
    opacity: 0.26559;
  }
  45% {
    opacity: 0.84693;
  }
  50% {
    opacity: 0.96019;
  }
  55% {
    opacity: 0.08594;
  }
  60% {
    opacity: 0.20313;
  }
  65% {
    opacity: 0.71988;
  }
  70% {
    opacity: 0.53455;
  }
  75% {
    opacity: 0.37288;
  }
  80% {
    opacity: 0.71428;
  }
  85% {
    opacity: 0.70419;
  }
  90% {
    opacity: 0.7003;
  }
  95% {
    opacity: 0.36108;
  }
  100% {
    opacity: 0.24387;
  }
}

/*Adds a "color separation flicker" effect to all text*/
.crt-colorsep {
  animation: textShadow 1.6s infinite;
}

@keyframes textShadow {
  0% {
    text-shadow:
      0.4389924193300864px 0 1px rgba(0, 30, 255, 0.5),
      -0.4389924193300864px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  5% {
    text-shadow:
      2.7928974010788217px 0 1px rgba(0, 30, 255, 0.5),
      -2.7928974010788217px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  10% {
    text-shadow:
      0.02956275843481219px 0 1px rgba(0, 30, 255, 0.5),
      -0.02956275843481219px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  15% {
    text-shadow:
      0.40218538552878136px 0 1px rgba(0, 30, 255, 0.5),
      -0.40218538552878136px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  20% {
    text-shadow:
      3.4794037899852017px 0 1px rgba(0, 30, 255, 0.5),
      -3.4794037899852017px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  25% {
    text-shadow:
      1.6125630401149584px 0 1px rgba(0, 30, 255, 0.5),
      -1.6125630401149584px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  30% {
    text-shadow:
      0.7015590085143956px 0 1px rgba(0, 30, 255, 0.5),
      -0.7015590085143956px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  35% {
    text-shadow:
      3.896914047650351px 0 1px rgba(0, 30, 255, 0.5),
      -3.896914047650351px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  40% {
    text-shadow:
      3.870905614848819px 0 1px rgba(0, 30, 255, 0.5),
      -3.870905614848819px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  45% {
    text-shadow:
      2.231056963361899px 0 1px rgba(0, 30, 255, 0.5),
      -2.231056963361899px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  50% {
    text-shadow:
      0.08084290417898504px 0 1px rgba(0, 30, 255, 0.5),
      -0.08084290417898504px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  55% {
    text-shadow:
      2.3758461067427543px 0 1px rgba(0, 30, 255, 0.5),
      -2.3758461067427543px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  60% {
    text-shadow:
      2.202193051050636px 0 1px rgba(0, 30, 255, 0.5),
      -2.202193051050636px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  65% {
    text-shadow:
      2.8638780614874975px 0 1px rgba(0, 30, 255, 0.5),
      -2.8638780614874975px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  70% {
    text-shadow:
      0.48874025155497314px 0 1px rgba(0, 30, 255, 0.5),
      -0.48874025155497314px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  75% {
    text-shadow:
      1.8948491305757957px 0 1px rgba(0, 30, 255, 0.5),
      -1.8948491305757957px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  80% {
    text-shadow:
      0.0833037308038857px 0 1px rgba(0, 30, 255, 0.5),
      -0.0833037308038857px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  85% {
    text-shadow:
      0.09769827255241735px 0 1px rgba(0, 30, 255, 0.5),
      -0.09769827255241735px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  90% {
    text-shadow:
      3.443339761481782px 0 1px rgba(0, 30, 255, 0.5),
      -3.443339761481782px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  95% {
    text-shadow:
      2.1841838852799786px 0 1px rgba(0, 30, 255, 0.5),
      -2.1841838852799786px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
  100% {
    text-shadow:
      2.6208764473832513px 0 1px rgba(0, 30, 255, 0.5),
      -2.6208764473832513px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
}

.start-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: -8px;
}

.context-menu {
  position: absolute;
  bottom: 60px; /* Adjust based on the height of your dock */
  left: 10px; /* Adjust based on the position of your start button */
  /* min-width: 250px; */
  border-radius: 8px;
  border: 1px solid #272727;
  background: #222;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  z-index: 1000; /* Ensure it's above everything else */
}

.context-menu a {
  text-decoration: none;
  color: inherit;
}
.context-menu-item {
  font-family: "Suisse", sans-serif;
  font-size: 12px;
  color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
}

.context-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.faux-window {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 400px;
  min-height: 100px;
  max-height: calc(
    50vh - 60px
  ); /* Assuming 60px is enough room for any system UI at the top of the screen */
  background: #222;
  border-radius: 8px;
  border: 1px solid #272727;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  z-index: 0;
  overflow: hidden; /* Prevent direct overflow of content */
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  resize: both;
}

@media screen and (max-width: 720px) {
  .faux-window {
    max-width: calc(100vw- 12px); /* Adjusting for possible system UI */
    max-height: calc(70vh - 30px); /* Adjusting for possible system UI */
    top: 24px !important;
    width: calc(100% - 12px);
    left: 6px !important;
    right: 6px;
    position: fixed;
  }
}

.faux-window-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #272727;
  background: #222;
  flex-shrink: 0; /* Ensure that toolbar doesn't shrink */
}

.faux-window-content {
  padding: 10px;
  color: #f1f1f1;
  overflow-y: auto; /* scroll if the content exceeds the max height */
  flex-grow: 1; /* Allow the content to grow and occupy the remaining space */
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  /*  background: linear-gradient(0deg, rgba(11, 11, 19, 0.25) 0%, rgba(11, 11, 19, 0.25) 100%), #242424;*/
  /*  border-bottom: 1px solid #000;*/
  color: #fff;
  font-family: "Suisse", sans-serif;
  font-size: 12px;
}

.settings {
  width: 400px;
  padding-left: 32px;
  padding-right: 32px;
  /* overflow: hidden; */
  position: fixed;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  border-radius: 20px;

  padding-top: 26px;
  padding-bottom: 26px;
  z-index: 100;
  /* height: 100%; */
}

@media screen and (max-width: 720px) {
  .settings {
    width: calc(75vw - 24px);
  }
}

.settings > h2 {
  font-weight: 400;
}

.settings h3 {
  font-weight: 400;
  color: #7f7f7f;
  font-size: 12px;
  margin-bottom: 6px;
}

.settings__window .faux-window-content {
  padding: 0;
}

.settings-caption {
  margin-top: 24px;
  margin-bottom: 12px;
}

.caption {
  font-family: monospace;
  font-size: 8px;

  text-align: center;
}

.right-section a {
  color: #fff;
  text-decoration: none;
}

.document-popup {
  max-width: 800px;
  white-space: pre-wrap;
}

.document-popup > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
