.container {
  background-color: #222;
  position: fixed;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 10;
  color: #f1f1f5;
  text-shadow: 0 0 0.32em #c8c8c8 !important;
  padding: 10px;
}

.container::after {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
 bottom: 0;
 right: 0;
  background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0 0.0625em, transparent 0.0625em 0.125em);
  -webkit-box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
  -moz-box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
  box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
}

.container__hidden {
  display: none;
}

.container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;

  background-size: 100vw;
  background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0 0.0625em, transparent 0.0625em 0.125em);
  -webkit-box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
  -moz-box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
  box-shadow: inset 0 0 12em rgba(0, 0, 0, 0.9);
}

.line {
  display: block;
  margin: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 0.2s;
  white-space: pre;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

.line__last {
  animation: blink 0.3s infinite;
}

@keyframes blink {
  0%   { opacity: 1; }
  49%   { opacity: 1; }
  50%  { opacity: 0; }
  99% {opacity: 0;}
  100% { opacity: 1; }
}

.line__small {
  font-size: 8px;
  font-family: "Xanh", monospace;

  background: linear-gradient(
      90deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 154, 0, 1) 10%,
      rgba(208, 222, 33, 1) 20%,
      rgba(79, 220, 74, 1) 30%,
      rgba(63, 218, 216, 1) 40%,
      rgba(47, 201, 226, 1) 50%,
      rgba(28, 127, 238, 1) 60%,
      rgba(95, 21, 242, 1) 70%,
      rgba(186, 12, 248, 1) 80%,
      rgba(251, 7, 217, 1) 90%,
      rgba(255, 0, 0, 1) 100%
  );
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 720px) {
  .line__small {
      font-size: 4px;
  }
 }