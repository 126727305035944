.container {
  font-family: 'Suisse', sans-serif;
  font-size: 12px;
  color: #F1F1F1;
  
  cursor: pointer;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #272727;

  transition: background-color 0.2s ease-in-out;
  padding-right: 24px;
}

.container:hover {
  border-radius: 8px;
  background-color: #272727;
}

.container:last-child {
  border-bottom: none;
}

.icon {
height: 32px;
width: 50px;
display: flex;
align-items: center;
justify-content: center;

fill: #F1F1F1;
filter: drop-shadow(0px 0px 3.5957446098327637px #FF3576) drop-shadow(0px 0px 14.382978439331055px rgba(255, 69, 57, 0.40));
}